<template>
    <div
        class="shadow white--bg"
        :style="`${
            !getDialogLocDiemDen
                ? 'height: 0px;border-radius: 16px;'
                : 'height: auto;border-radius: 0px 0px 16px 16px;'
        } transition: all 0.4s;overflow-y:auto;border: 1px solid #3d84b8;border-top: unset;`"
        id="popup-locdiemden"
    >
        <div class="container-md">
            <!-- ====================================================Danh sách điểm đến==================================================== -->
            <DxTabPanel
                :data-source="data.title"
                v-model:selected-index="selectedTab"
                :loop="true"
                :animation-enabled="true"
                :swipe-enabled="true"
                id="dxTab-loc-diemden"
            >
                <template #title="{ data: item }">
                    <span>{{ item.text }}</span>
                </template>
                <template #item="{ data: items }">
                    <div class="py-3" style="overflow-y: auto">
                        <!-- Danh sách tỉnh / thành phố -->
                        <div
                            class="row wrap align-center"
                            v-if="selectedTab == 0"
                        >
                            <!-- ItemTinh -->
                            <div
                                v-show="getDsTinh.length > 0"
                                class="xs2 my-1 justify-center align-center"
                                :style="`${
                                    index % 6 == 0
                                        ? ''
                                        : 'border-left:1px solid #ddd;'
                                } box-sizing: border-box;`"
                                v-for="(item, index) in getDsTinh"
                                :key="index"
                            >
                                <div
                                    :class="`py-2 mx-2 item-diadiem ellipsis ${
                                        item.active ? 'active' : ''
                                    }`"
                                    @click="chonTinh(item)"
                                    v-if="index != getDsTinh.length - 1"
                                >
                                    {{ item.TenViTri }}
                                </div>
                                <div
                                    v-if="
                                        index == getDsTinh.length - 1 &&
                                        soLuongTinhCanLay <= getDsTinh.length
                                    "
                                    class="py-2 mx-3 xemthem"
                                    @click="xemThemTinh"
                                >
                                    Xem thêm
                                </div>
                            </div>
                            <div v-show="getDsTinh.length == 0" class="error">
                                Không có dữ liệu Tỉnh/Thành phố!
                            </div>
                        </div>
                        <!-- Danh sách quận / huyện -->
                        <div
                            v-if="selectedTab == 1"
                            class="row wrap align-center"
                        >
                            <!-- ItemTinh -->
                            <div
                                v-show="getDsHuyen.length > 0"
                                class="xs2 my-1 justify-center align-center"
                                :style="`${
                                    index % 6 == 0
                                        ? ''
                                        : 'border-left:1px solid #ddd;'
                                } box-sizing: border-box;`"
                                v-for="(item, index) in getDsHuyen"
                                :key="index"
                            >
                                <div
                                    :class="`py-2 mx-2 item-diadiem ellipsis ${
                                        item.active ? 'active' : ''
                                    }`"
                                    @click="chonHuyen(item)"
                                >
                                    {{ item.TenViTri }}
                                </div>
                            </div>
                            <div v-show="getDsHuyen.length == 0" class="error">
                                Không có dữ liệu Quận/Huyện!
                            </div>
                        </div>
                        <!-- Danh sách phường / xã -->
                        <div
                            v-if="selectedTab == 2"
                            class="row wrap align-center"
                        >
                            <!-- ItemTinh -->
                            <div
                                v-show="getDsXa.length > 0"
                                class="xs2 my-1 justify-center align-center"
                                :style="`${
                                    index % 6 == 0
                                        ? ''
                                        : 'border-left:1px solid #ddd;'
                                } box-sizing: border-box;`"
                                v-for="(item, index) in getDsXa"
                                :key="index"
                            >
                                <div
                                    :class="`py-2 mx-2 item-diadiem ellipsis ${
                                        item.active ? 'active' : ''
                                    }`"
                                    @click="chonXa(item)"
                                >
                                    {{ item.TenViTri }}
                                </div>
                            </div>
                            <div v-show="getDsXa.length == 0" class="error">
                                Không có dữ liệu Phường/Xã!
                            </div>
                        </div>
                    </div>
                </template>
            </DxTabPanel>
        </div>
        <!-- ====================================================Tìm kiếm và lọc==================================================== -->
        <div class="locdiemden">
            <div class="row">
                <DxButton
                    icon="mdi mdi-filter-variant-remove"
                    text="Bỏ lọc"
                    height="38px"
                    id="btn-boloc"
                    stylingMode="text"
                    class="primary--text mr-2"
                    @click="boLoc"
                />
                <DxButton
                    icon="mdi mdi-magnify"
                    text="Tìm kiếm"
                    height="38px"
                    id="btn-timkiem2"
                    :rtlEnabled="true"
                    class="secondary--bg white--text"
                    @click="$emit('timKiem')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { DxTabPanel, DxButton } from "devextreme-vue";
export default {
    components: { DxTabPanel, DxButton },
    props: {},
    data() {
        return {
            selectedTab: 0,
            soLuongTinhCanLay: 17,
            data: {
                title: [
                    {
                        id: 1,
                        text: "Tỉnh/Thành phố",
                    },
                    {
                        id: 2,
                        text: "Quận/Huyện",
                    },
                    {
                        id: 3,
                        text: "Phường/Xã",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters("timKiemChuyenDi", [
            "getDialogLocDiemDen",
            "getDsTinh",
            "getDsHuyen",
            "getDsXa",
            "getTinhDangChon",
            "getHuyenDangChon",
            "getXaDangChon",
        ]),
    },
    methods: {
        ...mapMutations("timKiemChuyenDi", [
            "setTimKiem",
            "setTinhDangChon",
            "setHuyenDangChon",
            "setXaDangChon",
            "setData",
        ]),
        ...mapActions("timKiemChuyenDi", [
            "layDsTinh",
            "layDsHuyen",
            "layDsXa",
        ]),

        async chonTinh(item) {
            this.getDsTinh.forEach((e) => {
                if (item.id == e.id)
                    (e.active = !e.active),
                        (this.selectedTab = e.active ? 1 : ""),
                        e.active
                            ? this.setTinhDangChon(e)
                            : this.setTinhDangChon(null),
                        this.setTimKiem(e.active ? e.TenViTri : ""),
                        e.active
                            ? this.layDsHuyen()
                            : this.setData({ key: "dsHuyen", value: [] });
                else e.active = false;
            });
        },
        async chonHuyen(item) {
            this.getDsHuyen.forEach((e) => {
                if (item.id == e.id)
                    (e.active = !e.active),
                        (this.selectedTab = e.active ? 2 : ""),
                        e.active
                            ? this.setHuyenDangChon(e)
                            : this.setHuyenDangChon(null),
                        this.setTimKiem(
                            e.active
                                ? e.TenViTri
                                : this.getTinhDangChon.TenViTri
                        ),
                        e.active
                            ? this.layDsXa()
                            : this.setData({ key: "dsXa", value: [] });
                else e.active = false;
            });
        },
        async chonXa(item) {
            this.getDsXa.forEach((e) => {
                if (item.id == e.id)
                    (e.active = !e.active),
                        e.active
                            ? this.setXaDangChon(e)
                            : this.setXaDangChon(null),
                        this.setTimKiem(
                            e.active
                                ? e.TenViTri
                                : this.getHuyenDangChon.TenViTri
                        );
                else e.active = false;
            });
        },
        xemThemTinh(item) {
            this.soLuongTinhCanLay = this.soLuongTinhCanLay + 30;
            this.layDsTinh(this.soLuongTinhCanLay);
        },
        boLoc() {
            this.setTimKiem("");
            this.setTinhDangChon(null);
            this.setHuyenDangChon(null);
            this.setXaDangChon(null);
            this.getDsTinh.forEach((e) => {
                e.active = false;
            });
            this.selectedTab = 0;
        },
    },
    mounted() {
        this.layDsTinh(this.soLuongTinhCanLay);
    },
};
</script>
<style lang='scss' scoped>
#dxTab-loc-diemden :deep(.dx-tabs-wrapper),
#dxTab-loc-diemden :deep(.dx-item.dx-tab.dx-tab-selected) {
    background-color: white;
}
#dxTab-loc-diemden :deep(.dx-item-content) {
    text-transform: unset;
    font-size: 16px;
}
.locdiemden {
    position: absolute;
    right: 16px;
    top: 92px;
}
#btn-boloc :deep(.dx-icon) {
    font-size: 24px;
    color: $primary;
}
#btn-boloc :deep(.dx-button-text) {
    font-size: 18px;
    text-transform: unset;
    font-weight: unset;
}
#btn-timkiem2 :deep(.dx-icon) {
    font-size: 24px;
    color: white;
}
#btn-timkiem2 :deep(.dx-button-text) {
    font-size: 18px;
    font-weight: unset;
}
#btn-timkiem2 {
    box-shadow: unset;
}
.item-diadiem {
    border-radius: 4px;
    font-size: 18px;
}
.item-diadiem:active {
    background-color: #fcdbd8;
}
.xemthem {
    border: 1px solid $primary;
    color: $primary;
    border-radius: 4px;
}
.active {
    border: 1px solid $secondary;
    color: $secondary;
}
.error {
    text-align: center;
    width: 100%;
}
</style>









 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 